<template>
  <section>
    <CContainer>
      <CForm @submit.stop.prevent="Save()">
        <CCard>
          <CCardHeader class="d-flex justify-content-between align-items-center">
            <h5 class="mb-0">{{ Action === 'Edit' ? $t('Navigation.Post/Edit') : $t('Navigation.Post/Add') }}</h5>
            <div class="card-header-actions d-flex">
              <WidgetsLocales v-if="$store.state.user.permission.MutipleLocale" :NowChooseLanguage.sync="NowChooseLanguage"></WidgetsLocales>
              <CButton v-if="!$store.state.user.permission.MutipleLocale" color="info" class="mr-2" type="button" @click="SynchronizeToWordPress()">
                <i class="fab fa-wordpress mr-1" />{{ $t('Post/Detail.SyncToWP') }}
              </CButton>
              <CButton v-if="!$store.state.user.permission.MutipleLocale" color="success" type="submit">
                {{ Action === 'Edit' ? $t('Global.Update') : $t('Global.Add') }}
              </CButton>
            </div>
          </CCardHeader>
          <CCardBody>
            <CTabs fade addTabsWrapperClasses="tab-info">
              <CTab :title="$t('Post/Detail.Info')" active>
                <CRow>
                  <CCol md="8">
                    <CRow v-if="Action === 'Edit'" form class="form-group">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Global.URL') }}
                      </CCol>
                      <CCol sm="9" class="d-flex align-items-center">
                        <a v-if="Data.Slug" class="text-info mb-0 mr-2" :href="`https://${$store.state.user.permission.Domain}/posts/detail/${Data.Slug}`" target="_blank">
                          https://{{ $store.state.user.permission.Domain }}/posts/detail/{{ Data.Slug }}
                        </a>
                        <a v-if="Data.Slug" href="#" v-c-tooltip="{content: $t('Global.CopyURL'), placement: 'top'}" @click="CopyToClipboard(`https://${$store.state.user.permission.Domain}/posts/detail/${Data.Slug}`)" class="text-dark">
                          <i class="far fa-copy" />
                        </a>
                      </CCol>
                    </CRow>
                    <CInput
                        :label="$t('Post/Detail.Slug')"
                        v-model="Data.Slug"
                        horizontal
                    />
                    <CRow form class="form-group">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Post/Detail.AuthorAccount') }} <i v-c-tooltip="{content: '預設為當前登入帳號，同時也可以指定會員帳號', placement: 'bottom'}" class="fas fa-question-circle text-info ml-1" />
                      </CCol>
                      <CCol sm="9">
                        <multiselect
                          v-model="Data.Variable.Author"
                          :options="SearchAccountData"
                          :optionHeight="34"
                          label="Account"
                          track-by="UserID"
                          :options-limit="1000"
                          :limit="3"
                          :searchable="true"
                          :loading="OwnerSearchLoading"
                          @search-change="SearchAccount"
                          :placeholder="$t('Global.PleaseSelect')"
                          :selectLabel="$t('Global.SelectLabel')"
                          :selectedLabel="$t('Global.SelectedLabel')"
                          :deselectLabel="$t('Global.DeselectLabel')"
                        >
                          <template #noOptions>
                            {{ $t('Post/Detail.AuthorSearch') }}
                          </template>
                          <template #noResult>
                            {{ $t('Global.NoOptions') }}
                          </template>
                        </multiselect>
                      </CCol>
                    </CRow>
                    <CRow form class="form-group">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Post/Detail.Categories') }}
                      </CCol>
                      <CCol sm="9">
                        <multiselect
                            v-model="SelectCategories"
                            :options="CategoryList"
                            :optionHeight="34"
                            label="label"
                            track-by="value"
                            :placeholder="$t('Global.PleaseSelect')"
                            :selectLabel="$t('Global.SelectLabel')"
                            :selectedLabel="$t('Global.SelectedLabel')"
                            :deselectLabel="$t('Global.DeselectLabel')"
                            :multiple="true"
                            @input="SynchronizeCategories()"
                        >
                          <template #noOptions>
                            {{ $t('Global.NoOptions') }}
                          </template>
                        </multiselect>
                      </CCol>
                    </CRow>
                    <template v-if="NowChooseLanguage === 'default'">
                      <CInput
                          :label="$t('Post/Detail.Name')"
                          v-model="Data.Name"
                          horizontal
                      />
                      <CInput
                          :label="$t('Post/Detail.OtherName')"
                          v-model="Data.OtherName"
                          horizontal
                      />
                      <CInput
                          :label="$t('Post/Detail.HeaderText')"
                          v-model="Data.HeaderText"
                          horizontal
                      />
                      <CInput
                          type="text"
                          :label="$t('Post/Detail.Attachment')"
                          v-model="Data.Attachment"
                          horizontal
                      >
                        <template #append>
                          <CButton type="button" color="dark" @click="OpenMediaStore('Attachment', false)">{{ $t('Global.Browse') }}</CButton>
                        </template>
                      </CInput>
                      <CRow form class="form-group">
                        <CCol tag="label" sm="3" class="col-form-label">
                          {{ $t('Post/Detail.Tags') }}
                        </CCol>
                        <CCol sm="9">
                          <multiselect
                              :key="NowChooseLanguage"
                              v-model="Data.Tags"
                              :tag-placeholder="$t('Global.EnterToAdd')"
                              :placeholder="$t('Global.PleaseKeyIn')"
                              :deselectLabel="$t('Global.DeselectLabel')"
                              :selectedLabel="$t('Global.SelectedLabel')"
                              :options="Data.Tags"
                              :multiple="true"
                              :taggable="true"
                              @tag="AddTag">
                            <template #noOptions>
                              {{ $t('Global.NoOptions') }}
                            </template>
                          </multiselect>
                        </CCol>
                      </CRow>
                      <CTextarea :label="$t('Post/Detail.Introduction')" v-model="Data.Introduction" rows="4" horizontal />
                    </template>
                    <template v-else>
                      <CInput
                          :label="$t('Post/Detail.Name')"
                          v-model="Data.LanguageData[NowChooseLanguage].Name"
                          horizontal
                      />
                      <CInput
                          :label="$t('Post/Detail.OtherName')"
                          v-model="Data.LanguageData[NowChooseLanguage].OtherName"
                          horizontal
                      />
                      <CInput
                          :label="$t('Post/Detail.HeaderText')"
                          v-model="Data.LanguageData[NowChooseLanguage].HeaderText"
                          horizontal
                      />
                      <CInput
                          type="text"
                          :label="$t('Post/Detail.Attachment')"
                          v-model="Data.LanguageData[NowChooseLanguage].Attachment"
                          horizontal
                      >
                        <template #append>
                          <CButton type="button" color="dark" @click="OpenMediaStore('Attachment', false)">{{ $t('Global.Browse') }}</CButton>
                        </template>
                      </CInput>
                      <CRow form class="form-group">
                        <CCol tag="label" sm="3" class="col-form-label">
                          {{ $t('Post/Detail.Tags') }}
                        </CCol>
                        <CCol sm="9">
                          <multiselect
                              :key="NowChooseLanguage"
                              v-model="Data.LanguageData[NowChooseLanguage].Tags"
                              :tag-placeholder="$t('Global.EnterToAdd')"
                              :placeholder="$t('Global.PleaseKeyIn')"
                              :deselectLabel="$t('Global.DeselectLabel')"
                              :selectedLabel="$t('Global.SelectedLabel')"
                              :options="Data.LanguageData[NowChooseLanguage].Tags"
                              :multiple="true"
                              :taggable="true"
                              @remove="RemoveLocaleTag"
                              @tag="AddTag">
                            <template #noOptions>
                              {{ $t('Global.NoOptions') }}
                            </template>
                          </multiselect>
                        </CCol>
                      </CRow>
                      <CTextarea :label="$t('Post/Detail.Introduction')" v-model="Data.LanguageData[NowChooseLanguage].Introduction" rows="2" horizontal />
                    </template>
                  </CCol>
                  <CCol md="4">
                    <CRow>
                      <CCol tag="label" sm="3" class="col-form-label" for="CreateTime">
                        {{ $t('Post/Detail.CreateTime') }}
                      </CCol>
                      <CCol sm="9">
                        <v-date-picker v-model="Data.CreateTime" mode="dateTime" is24hr>
                          <template v-slot="{ inputValue, inputEvents }">
                            <CInput placeholder="YYYY-MM-DD HH:mm" :value="inputValue" v-on="inputEvents">
                              <template #append-content>
                                <CIcon name="cil-calendar"/>
                              </template>
                            </CInput>
                          </template>
                        </v-date-picker>
                      </CCol>
                    </CRow>
                    <CInput
                        :label="$t('Post/Detail.Sort')"
                        v-model="Data.Sort"
                        horizontal
                    />
                    <CSelect horizontal :label="$t('Post/Detail.Status')" :options="StatusOptions" v-model="Data.Status" :value.sync="Data.Status" :placeholder="$t('Global.PleaseSelect')" />
                  </CCol>
                </CRow>
              </CTab>
              <CTab :title="$t('Post/Detail.Images')">
                <h5>
                  {{ $t('Post/Detail.Carousel') }}
                </h5>
                <hr>
                <template v-if="NowChooseLanguage === 'default'">
                  <Draggable
                      v-model="Data.Carousel"
                      class="row no-gutters mx-n1 mb-3"
                      tag="div"
                      v-bind="DragOptions"
                      @start="Drag = true"
                      @end="Drag = false"
                  >
                    <CCol v-for="(Data, Index) in Data.Carousel" :key="Index" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="position-relative CarouselItem">
                        <img v-lazy="Data.URL" class="img-fluid" />
                        <div class="CarouselTools">
                          <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Carousel', false, Index)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                          <CButton color="secondary" size="sm" class="mx-1" @click="OpenMediaMeta('Carousel', Index)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                          <CButton color="danger" size="sm" class="mx-1" @click="RemoveImage('Carousel', Index)">
                            <CIcon name="cil-trash" />
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                    <CCol xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                        <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                        <div class="CarouselTools">
                          <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Carousel')">
                            <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                  </Draggable>
                </template>
                <template v-else>
                  <Draggable
                      v-model="Data.LanguageData[NowChooseLanguage].Carousel"
                      class="row no-gutters mx-n1 mb-3"
                      tag="div"
                      v-bind="DragOptions"
                      @start="Drag = true"
                      @end="Drag = false"
                  >
                    <CCol v-for="(Data, Index) in Data.LanguageData[NowChooseLanguage].Carousel" :key="Index" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="position-relative CarouselItem">
                        <img v-lazy="Data.URL" class="img-fluid" />
                        <div class="CarouselTools">
                          <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Carousel', false, Index)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                          <CButton color="secondary" size="sm" class="mx-1" @click="OpenMediaMeta('Carousel', Index)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                          <CButton color="danger" size="sm" class="mx-1" @click="RemoveImage('Carousel', Index)">
                            <CIcon name="cil-trash" />
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                    <CCol xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                        <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                        <div class="CarouselTools">
                          <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Carousel')">
                            <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                  </Draggable>
                </template>
                <h5>{{ $t('Post/Detail.Cover') }}</h5>
                <hr>
                <CRow class="no-gutters mx-n1 mb-3">
                  <template v-if="NowChooseLanguage === 'default'">
                    <CCol v-if="Data.Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="position-relative CarouselItem">
                        <img v-lazy="Data.Cover" class="img-fluid" />
                        <div class="CarouselTools">
                          <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                          <CButton color="secondary" size="sm" class="mx-1" @click="OpenMediaMeta('Cover', false)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                    <CCol v-if="!Data.Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                        <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                        <div class="CarouselTools">
                          <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                            <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                  </template>
                  <template v-else>
                    <CCol v-if="Data.LanguageData[NowChooseLanguage].Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="position-relative CarouselItem">
                        <img v-lazy="Data.LanguageData[NowChooseLanguage].Cover" class="img-fluid" />
                        <div class="CarouselTools">
                          <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                          <CButton color="secondary" size="sm" class="mx-1" @click="OpenMediaMeta('Cover', false)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                    <CCol v-if="!Data.LanguageData[NowChooseLanguage].Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                        <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                        <div class="CarouselTools">
                          <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                            <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                          </CButton>
                          <CButton color="secondary" size="sm" class="mx-1" @click="OpenMediaMeta('Cover', false)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                  </template>
                </CRow>
              </CTab>
              <CTab :title="$t('Post/Detail.Content')">
                <Editor api-key="no-api-key" tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.10.9/tinymce.min.js" :init="TinyMCE" v-model="CurrentContent" />
              </CTab>
              <CTab :title="$t('Post/Detail.Message')">
                <template v-if="Data.Messages && Data.Messages.length > 0">
                  <CAlert color="info">
                    <CIcon name="cil-bell" /> 修改審核狀態與刪除後，您需要送出更新後變更才會生效。
                  </CAlert>
                  <CRow>
                    <CCol xl="4">
                      <CListGroup class="CommentList" accent>
                        <CListGroupItem v-for="(Item, Index) in Data.Messages" href="#" class="flex-column align-items-start" :active="(MessageActive === Index)" accent="light" :color="(MessageActive === Index ? 'dark' : 'light')" :key="Index" @click="MessageActive = Index;MessageData = Data.Messages[Index]">
                          <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1 font-weight-bold">
                              {{ Item.Name }}
                            </h5>
                            <small>{{ Item.CreateTime }}</small>
                          </div>
                          <div class="d-flex w-100 justify-content-between">
                            <small class="text-truncate" style="max-width: 300px;">{{ Item.Content }}</small>
                          </div>
                        </CListGroupItem>
                      </CListGroup>
                    </CCol>
                    <CCol xl="8">
                      <CCard v-if="MessageData._id">
                        <CCardHeader class="d-flex justify-content-between align-items-center">
                          <h5 class="mb-0 font-weight-bold">
                            {{ MessageData.Name }} - {{ MessageData.Subject }}
                          </h5>
                          <div class="ButtonGroup">
                            <CButton size="sm" color="danger" shape="pill" @click="Data.Messages.splice(MessageActive, 1)">
                              <CIcon size="sm" name="cil-trash" />
                            </CButton>
                          </div>
                        </CCardHeader>
                        <CCardBody>
                          <CRow form class="form-group">
                            <CCol tag="label" sm="2" class="col-form-label">
                              {{ $t('Post/Detail.MessageAudit') }}
                            </CCol>
                            <CCol sm="10">
                              <CSwitch color="success" :checked.sync="MessageData.Audit" />
                            </CCol>
                          </CRow>
                          <p class="mb-1">
                            {{ MessageData.Content }}
                          </p>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow>
                </template>
                <CAlert v-else color="secondary">
                  <CIcon name="cil-bell" /> {{ $t('Post/Detail.NoMessage') }}
                </CAlert>
              </CTab>
              <CTab v-if="Features.includes('Permission')" :title="$t('Post/Detail.Permission')">
                <CRow>
                  <CCol lg="4">
                    <CRow form class="form-group">
                      <CCol tag="label" sm="6" class="col-form-label">
                        {{ $t('Post/Detail.PermissionEnable') }}
                      </CCol>
                      <CCol sm="6">
                        <CSwitch color="success" :checked.sync="Data.Permission.Enable" />
                      </CCol>
                    </CRow>
                    <CRow v-if="Data.Permission.Enable" form class="form-group">
                      <CCol tag="label" sm="6" class="col-form-label">
                        {{ $t('Post/Detail.PermissionMemberRequired') }}
                      </CCol>
                      <CCol sm="6">
                        <CSwitch color="success" :checked.sync="Data.Permission.MemberRequired" />
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol lg="8">
                  </CCol>
                </CRow>
              </CTab>
              <CTab :title="$t('Post/Detail.SEO')">
                <CRow>
                  <CCol md="8">
                    <template v-if="NowChooseLanguage === 'default'">
                      <CInput
                        :label="$t('Post/Detail.SEO/Title')"
                        v-model="Data.SEO.Title"
                        horizontal
                      />
                      <CInput
                          :label="$t('Post/Detail.SEO/Keyword')"
                          v-model="Data.SEO.Keywords"
                          horizontal
                      />
                      <CInput
                          :label="$t('Post/Detail.SEO/Description')"
                          v-model="Data.SEO.Description"
                          horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/OG:Title')"
                        v-model="Data.SEO.OGTitle"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/OG:Description')"
                        v-model="Data.SEO.OGDescription"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/OG:Image')"
                        v-model="Data.SEO.OGImage"
                        horizontal
                      />
                    </template>
                    <template v-else>
                      <CInput
                        :label="$t('Post/Detail.SEO/Title')"
                        v-model="Data.LanguageData[NowChooseLanguage].SEO.Title"
                        horizontal
                      />
                      <CInput
                          :label="$t('Post/Detail.SEO/Keyword')"
                          v-model="Data.LanguageData[NowChooseLanguage].SEO.Keywords"
                          horizontal
                      />
                      <CInput
                          :label="$t('Post/Detail.SEO/Description')"
                          v-model="Data.LanguageData[NowChooseLanguage].SEO.Description"
                          horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/OG:Title')"
                        v-model="Data.LanguageData[NowChooseLanguage].SEO.OGTitle"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/OG:Description')"
                        v-model="Data.LanguageData[NowChooseLanguage].SEO.OGDescription"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/OG:Image')"
                        v-model="Data.LanguageData[NowChooseLanguage].SEO.OGImage"
                        horizontal
                      />
                    </template>
                  </CCol>
                </CRow>
              </CTab>
              <CTab title="連動">
                <h5>
                  <i class="fab fa-wordpress mr-1" />WordPress
                </h5>
                <hr>
                <CRow>
                  <CCol md="8">
                    <CInput
                      label="文章ID"
                      v-model="Data.Variable.WP_PostID"
                      horizontal
                    />
                  </CCol>
                </CRow>
              </CTab>
              <CTab v-if="Object.keys(VariableExtend).length === 0" :title="$t('Post/Detail.Custom')">
                <CRow>
                  <CCol md="12">
                    <CDataTable
                      :items="VariableList"
                      :fields="VariableField"
                      :noItemsView="VariableNoItems"
                      responsive
                      hover
                    >
                      <template #Index="{item}">
                        <td>
                          <CInput v-model="item.Index" size="sm" type="text" @input="HandleVariable()" />
                        </td>
                      </template>
                      <template #Value="{item}">
                        <td>
                          <CTextarea v-model="item.Value" size="sm" rows="1" @input="HandleVariable()" />
                        </td>
                      </template>
                      <template #Type="{item}">
                        <td>
                          <CSelect :options="TypeList" v-model="item.Type" :value.sync="item.Type" size="sm" :placeholder="$t('Global.PleaseSelect')" />
                        </td>
                      </template>
                      <template #Action="{index}">
                        <td>
                          <CButton color="danger" type="button" size="sm" @click="RemoveVariable(index)">
                            <CIcon name="cil-trash" class="c-icon-custom-size" />
                          </CButton>
                        </td>
                      </template>
                    </CDataTable>
                    <CButton color="info" type="button" size="sm" @click="AddVariable()">
                      {{ $t('Post/Detail.AddVariable') }}
                    </CButton>
                  </CCol>
                </CRow>
              </CTab>
              <CTab :title="$t('Post/Detail.Setting')">
                <template v-if="(Object.keys(VariableExtend).length > 0)">
                  <template v-for="(Config, Variable) in VariableExtend">
                    <h5 :key="Variable+ '-title'">
                      {{ $t('Post/ExtendVariable.' + Variable) }}
                    </h5>
                    <hr :key="Variable + '-hr'">
                    <Editor v-if="Config.FormType === 'editor'" :key="Variable + '-content'" api-key="no-api-key" tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.10.9/tinymce.min.js" :init="TinyMCE" v-model="Data.Variable[Variable]" />
                    <CInput v-if="Config.FormType === 'text'" type="text" :key="Variable + '-content'" v-model="Data.Variable[Variable]" />
                    <CTextarea v-if="Config.FormType === 'textarea'" row="3" :key="Variable + '-content'" v-model="Data.Variable[Variable]" />
                    <template v-if="Config.FormType === 'array'">
                      <div class="mb-3" :key="Variable + '-content'">
                        <CInput v-for="(Data, Index) in Data.Variable[Variable]" type="text" :key="'Array-' + Variable + Index" v-model="Data.Value">
                          <template #append>
                            <CButton color="light" @click="RemoveVariableArrayInput(Variable, Index)">
                              <CIcon class="my-0" name="cil-trash" size="sm"/>
                            </CButton>
                          </template>
                        </CInput>
                        <CButton :key="Variable + '-btn'" color="info" size="sm" type="button" @click="AddVariableArrayInput(Variable)">
                          <CIcon name="cil-plus" class="mr-1" /> {{ $t('Global.Add') }}
                        </CButton>
                      </div>
                    </template>
                  </template>
                </template>
                <CAlert v-else color="warning">{{ $t('Product/Detail.NoSetting') }}</CAlert>
              </CTab>
            </CTabs>
          </CCardBody>
          <CCardFooter class="text-right">
            <CButton color="info" class="mr-2" type="button" @click="SynchronizeToWordPress()">
              <i class="fab fa-wordpress mr-1" />{{ $t('Post/Detail.SyncToWP') }}
            </CButton>
            <CButton color="success" type="submit">
              {{ Action === 'Edit' ? $t('Global.Update') : $t('Global.Add') }}
            </CButton>
          </CCardFooter>
        </CCard>
        <CElementCover v-if="(Loading === true)" :opacity="0.75">
          <CSpinner color="success"/>
        </CElementCover>
      </CForm>
    </CContainer>
    <MediaStore :Config="MediaStoreConfig" ref="MediaStore" @OK="SetImages(ChooseImageType)"></MediaStore>
    <MediaMeta :Config="MediaMetaConfig" @OK="SetMeta"></MediaMeta>
    <input id="tinymce-image-input" type="text" class="d-none">
  </section>
</template>

<route>
{
  "meta": {
    "label": "文章編輯"
  }
}
</route>

<script>
import DataSchema from '@/schema/post/detail'
// import LanguageDataSchema from '@/schema/post/languageData'
import Editor from '@tinymce/tinymce-vue'
import TinyMCESetting from '@/plugins/tinymce'

export default {
  name: 'EditPost',
  layout: 'manage',
  components: {
    Multiselect: () => import('@/plugins/mutiselect'),
    MediaStore: () => import('@/components/public/Media'),
    MediaMeta: () => import('@/components/public/MediaMeta'),
    WidgetsLocales: () => import('@/components/widgets/WidgetsLocales'),
    Draggable: () => import('vuedraggable'),
    Editor
  },
  data () {
    return {
      Action: 'Edit',
      Data: {}, //主資料
      Features: [],
      OwnerSearchLoading: false,
      SearchAccountData: [],
      TempAccount: '',

      OldSlug: '',
      SelectCategories: [], //格式化後的已選商品分類
      PostStatus: false, //格式化後的狀態開關
      EnableResponseContent: false, //格式化後的響應式商品內容開關
      Debug: false,
      CategoryList: [],
      VariableList: [],
      TinyMCE: {
        ...TinyMCESetting,
        file_picker_callback: (callback) => {
          this.OpenMediaStore('Editor', false)
          const input = document.getElementById('tinymce-image-input')
          input.addEventListener('change', (event) => {
            callback(event.target.value, {title: ''})
          })
        },
      },
      NowChooseDevice: 'Web',
      NowChooseLanguage: 'default',
      Loading: false,
      Drag: false,
      ChooseImageType: '',
      ChooseImageIndex: false,
      MediaStoreConfig: {
        Display: false,
        ChooseImageList: [],
        Multiple: false
      },
      MediaMetaConfig: {
        Display: false,
        ImageMeta: {
          URL: ''
        }
      },
      MessageActive: null,
      MessageData: {},
      VariableExtend: {},
      TypeList: ['boolean', 'string', 'number', 'object', 'array'],
      VariableNoItems: {
        noResults: this.$t('Post/Detail.NoVariableItems'),
        noItems: this.$t('Post/Detail.NoVariableItems')
      }
    }
  },
  computed: {
    VariableField() {
      return [
        { key: 'Index', label: this.$t('Post/Detail.Index') },
        { key: 'Value', label: this.$t('Post/Detail.Value') },
        { key: 'Type', label: this.$t('Post/Detail.Type') },
        { key: 'Action', label: '' }
      ]
    },
    DragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    CurrentContent: {
      get () {
        if (this.NowChooseLanguage !== 'default') {
          if (this.Data.LanguageData[this.NowChooseLanguage]) {
            if (this.EnableResponseContent) {
              switch (this.NowChooseDevice) {
                case 'Web':
                case 'Mobile':
                  if (this.Data.LanguageData[this.NowChooseLanguage].ResponseData[this.NowChooseDevice]) {
                    return this.Data.LanguageData[this.NowChooseLanguage].ResponseData[this.NowChooseDevice].Content
                  }
              }
            }
            return this.Data.LanguageData[this.NowChooseLanguage].Content
          }
        }
        if (this.EnableResponseContent) {
          switch (this.NowChooseDevice) {
            case 'Web':
            case 'Mobile':
              if (this.Data.ResponseData[this.NowChooseDevice]) {
                return this.Data.ResponseData[this.NowChooseDevice].Content
              }
          }
        }
        return this.Data.Content
      },
      set (value) {
        if (this.NowChooseLanguage !== 'default') {
          if (this.Data.LanguageData[this.NowChooseLanguage]) {
            if (this.EnableResponseContent) {
              switch (this.NowChooseDevice) {
                case 'Web':
                case 'Mobile':
                  if (this.Data.LanguageData[this.NowChooseLanguage].ResponseData[this.NowChooseDevice]) {
                    this.Data.LanguageData[this.NowChooseLanguage].ResponseData[this.NowChooseDevice].Content = value
                  }
              }
            } else {
              this.Data.LanguageData[this.NowChooseLanguage].Content = value
            }
          }
        } else {
          if (this.EnableResponseContent) {
            switch (this.NowChooseDevice) {
              case 'Web':
              case 'Mobile':
                if (this.Data.ResponseData[this.NowChooseDevice]) {
                  this.Data.ResponseData[this.NowChooseDevice].Content = value
                }
            }
          } else {
            this.Data.Content = value
          }
        }
      }
    },
    StatusOptions () {
      return Object.keys(this.$t('Post/Detail.StatusOptions')).map(status => {
        return {
          value: parseInt(status),
          label: this.$t('Post/Detail.StatusOptions')[status]
        }
      })
    }
  },
  created() {
    this.$set(this.$data, 'Data', Object.assign({}, DataSchema))
    this.Action = (this.$route.name === 'post-detail-add' ? 'Add' : 'Edit')
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: 'error'
      })
    })
  },
  methods: {
    Init() {
      const PermissionSession = JSON.parse(localStorage.getItem('Permission'))
      this.Features = PermissionSession.Features.Post ? (PermissionSession.Features.Post.Add || []) : []
      const Functions = [
        this.GetCategories(),
        this.GetExtendData()
      ]
      if (this.Action === 'Edit') Functions.push(this.GetData())
      return Promise.all(Functions).then(() => {
        this.HandleExtendData()
        //詳細資料處理
        if (this.Action === 'Edit') this.HandleDetailData()
        this.LoadCurrentAccountData()
        //多語系資料處理
        if (this.$store.state.user.permission.MutipleLocale && this.Action === 'Add') {
          this.$set(this.Data, 'LanguageData', {})
          this.$store.state.user.permission.Locales.forEach((locale) => {
            this.$set(this.Data.LanguageData, locale, {
              Name: '',
              OtherName: '',
              Introduction: '',
              HeaderText: '',
              Content: '',
              Attachment: '',
              Tags: [],
              SEO: {
                Description: '',
                Keywords: ''
              },
              Cover: '',
              Carousel: [],
              Gallery: [],
              OverViewImages: [],
              ResponseData: {
                Mobile: {
                  Content: ''
                },
                Web: {
                  Content: ''
                }
              }
            })
          })
        }
        this.NowChooseLanguage = this.$store.state.user.permission.MutipleLocale ? this.$store.state.user.permission.DefaultLocale : 'default'
        return true
      }).catch((err) => {
        throw err
      })
    },
    Save() {
      let Action
      this.Data.Slug = this.Data.Slug.replace(/\//g, '_')
      switch (this.Action) {
        case 'Edit':
          Action = this.Edit()
          break
        case 'Add':
          Action = this.Add()
          break
        default:
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Error'),
            text: this.$t('Message.Post/SaveFail-1'),
            type: 'error'
          })
          return
      }
      this.Loading = true
      Action.then((result) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: result.msg,
          type: 'success'
        })
        if (this.Action === 'Add') {
          this.$router.replace('/post/detail/' + result.data.Slug)
        }
        if (this.Action === 'Edit' && this.OldSlug !== this.Data.Slug) {
          this.$router.replace('/post/detail/' + this.Data.Slug)
        }
      }).catch((err) => {
        console.log(err)
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error') + '[' + err.code  + ']',
          text: this.$t('Message.Post/SaveFail-2') + err.msg,
          type: 'error'
        })
      })
    },
    Add() {
      if (this.Data.Slug === '') {
        return Promise.reject({
          code: 403,
          msg: this.$t('Message.Post/SlugEmpty')
        })
      }
      //語系資料同步到預設值
      if (this.$store.state.user.permission.MutipleLocale) {
        this.SynchronizeDefaultLocaleData()
      }
      // 若沒有代稱則使用文章名稱直接帶入代稱
      if (!this.Data.Slug) {
        this.Data.Slug = this.Data.Name + '-' + this.$dayjs().unix()
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/content/add',
        method: 'post',
        data: {
          ...this.Data,
          Status: parseInt(this.Data.Status),
          EnableResponseContent: this.EnableResponseContent ? 1 : 0
        }
      }).then(({data}) => {
        return {
          ...data,
          msg: this.$t('Message.Post/AddSuccess')
        }
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    LoadCurrentAccountData () {
      this.SearchAccountData = [
        {
          Account: this.$store.state.user.data.email + `(${this.$store.state.user.data.displayName})`,
          Name: this.$store.state.user.data.displayName,
          Email: this.$store.state.user.data.email,
          Cover: this.$store.state.user.data.photoURL,
          Uid: '--',
          Class: 'Manage'
        }
      ]
      return Promise.resolve(true)
    },
    Edit() {
      if (this.Data.Slug === '') {
        return Promise.reject({
          code: 403,
          msg: this.$t('Message.Post/SlugEmpty')
        })
      }
      //語系資料同步到預設值
      if (this.$store.state.user.permission.MutipleLocale) {
        this.SynchronizeDefaultLocaleData()
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/content/edit',
        method: 'post',
        data: {
          slug: this.OldSlug,
          updateData: {
            ...this.Data,
            Status: parseInt(this.Data.Status),
            EnableResponseContent: this.EnableResponseContent ? 1 : 0
          }
        }
      }).then(() => {
        return {
          msg: this.$t('Message.Post/EditSuccess')
        }
      }).catch((err) => {
        throw err
      })
    },
    GetData() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/content/detail',
        method: 'get',
        params: {
          slug: this.$route.params.slug
        }
      }).then(({data}) => {
        this.Loading = false
        this.Data = data.detail
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    GetExtendData() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/content/extend',
        method: 'get'
      }).then(({data}) => {
        this.Loading = false
        this.VariableExtend = data || {}
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    GetCategories () {
      return this.$store.dispatch('InnerRequest', {
        url: '/content/category/list',
        method: 'get',
        params: {
          perPage: 100
        }
      }).then(({data}) => {
        this.CategoryList = data.list.map((category) => {
          return { label: category.Name, value: category.Slug }
        })
      }).catch((err) => {
        throw err
      })
    },
    HandleDetailData() {
      this.SelectCategories = this.CategoryList.filter((data) => {
        return this.Data.Categories.includes(data.value)
      })

      this.OldSlug = this.Data.Slug
      this.PostStatus = (this.Data.Status === 1)
      this.EnableResponseContent = (this.Data.EnableResponseContent === 1)

        //擴充參數載入
        if (this.Data.Variable) {
            this.VariableList = Object.keys(this.Data.Variable).map((data) => {
                let Value, Type = typeof this.Data.Variable[data]
                switch (typeof this.Data.Variable[data]) {
                    case 'boolean':
                    case 'string':
                    case 'undefined':
                    case 'number':
                        Value = this.Data.Variable[data]
                        break
                    case 'object':
                        Value = JSON.stringify(this.Data.Variable[data])
                        if (Array.isArray(this.Data.Variable[data])) {
                            Type = 'array'
                        }
                        break
                }
                return {
                    Index: data,
                    Value,
                    Type
                }
            })
        }

      //多語系資料處理
      if (this.$store.state.user.permission.MutipleLocale) {
        if (!this.Data.LanguageData) {
          this.$set(this.Data, 'LanguageData', {})
          this.$store.state.user.permission.Locales.forEach((locale) => {
            this.$set(this.Data.LanguageData, locale, {
              Name: '',
              OtherName: '',
              Introduction: '',
              HeaderText: '',
              Content: '',
              Attachment: '',
              Tags: [],
              SEO: {
                Description: '',
                Keywords: ''
              },
              Cover: '',
              Carousel: [],
              Gallery: [],
              OverViewImages: [],
              ResponseData: {
                Mobile: {
                  Content: ''
                },
                Web: {
                  Content: ''
                }
              }
            })
          })
        }
        this.NowChooseLanguage = this.$store.state.user.permission.DefaultLocale || 'default'
      }
    },
    HandleVariable() {
      this.Data.Variable = {}
      this.VariableList.forEach((data) => {
        this.Data.Variable[data.Index] = data.Value
      })
    },
    HandleExtendData() {
      Object.keys(this.VariableExtend).forEach((Variable) => {
        if (!this.Data.Variable[Variable]) this.$set(this.Data.Variable, Variable, '')
      })
    },
    SynchronizeDefaultLocaleData() {
      const DefaultLocale = this.$store.state.user.permission.DefaultLocale
      if (DefaultLocale !== 'default') {
        this.Data.Name = this.Data.LanguageData[DefaultLocale].Name
        this.Data.OtherName = this.Data.LanguageData[DefaultLocale].OtherName
        this.Data.Introduction = this.Data.LanguageData[DefaultLocale].Introduction
        this.Data.HeaderText = this.Data.LanguageData[DefaultLocale].HeaderText
        this.Data.Content = this.Data.LanguageData[DefaultLocale].Content
        this.Data.Attachment = this.Data.LanguageData[DefaultLocale].Attachment
        this.Data.SEO = this.Data.LanguageData[DefaultLocale].SEO
        this.Data.Cover = this.Data.LanguageData[DefaultLocale].Cover
        this.Data.Carousel = this.Data.LanguageData[DefaultLocale].Carousel
        this.Data.Gallery = this.Data.LanguageData[DefaultLocale].Gallery
        this.Data.OverViewImages = this.Data.LanguageData[DefaultLocale].OverViewImages
        this.Data.ResponseData = this.Data.LanguageData[DefaultLocale].ResponseData
      }
    },
    SynchronizeCategories() {
      this.Data.Categories = this.SelectCategories.map(data => {
        return data.value
      })
    },
    AddTag (newTag) {
      this.Data.Tags.push(newTag)
      if (this.NowChooseLanguage !== 'default') {
        this.Data.LanguageData[this.NowChooseLanguage].Tags.push(newTag)
      }
    },
    RemoveLocaleTag (removedOption) {
      this.Data.Tags.splice(this.Data.Tags.indexOf(removedOption), 1)
    },
    AddVariable() {
      this.VariableList.push({
        Index: '',
        Value: '',
        Type: 'string'
      })
      this.HandleVariable()
    },
    RemoveVariable(index) {
      this.VariableList.splice(index, 1)
      this.HandleVariable()
    },
    OpenMediaStore(type = 'Carousel', multiple = true, index = false) {
      this.ChooseImageType = type
      this.MediaStoreConfig.ChooseImageList = []
      this.MediaStoreConfig.Display = !this.MediaStoreConfig.Display
      this.MediaStoreConfig.Multiple = multiple
      if (index !== false) {
        this.ChooseImageIndex = index
      }
    },
    async SetImages (type = 'Carousel') {
      switch (type) {
        case 'Cover':
        case 'Attachment':
          this.SetSingleImage(type)
          break
        case 'Editor':
          document.getElementById('tinymce-image-input').value = await this.ResolveImageURL()
          document.getElementById('tinymce-image-input').dispatchEvent(new Event('change'));
          break
        default:
          this.SetMultipleImages(type)
          break
      }
    },
    SetSingleImage(type, index) {
      if (this.$store.state.user.permission.StaticDomain) {
        if (this.NowChooseLanguage === 'default') {
          if (index) this.Data[type][index] = this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList
          else this.$set(this.Data, type, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
        } else {
          if (index) this.Data.LanguageData[this.NowChooseLanguage][type][index] = this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList
          else this.$set(this.Data.LanguageData[this.NowChooseLanguage], type, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
        }
      } else {
        this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
          if (this.NowChooseLanguage === 'default') {
            if (index) this.Data[type][index] = URL
            else this.$set(this.Data, type, URL)
          } else {
            if (index) this.Data.LanguageData[this.NowChooseLanguage][type][index] = URL
            else this.$set(this.Data.LanguageData[this.NowChooseLanguage], type, URL)
          }
        })
      }
    },
    SetMultipleImages(type) {
      if (typeof this.Data[type] === 'undefined') {
        this.$set(this.Data, type, [])
      }
      this.ChooseImageType = ''
      const storageInstance = this.$store.state.userProjectApp.storage()
      switch (typeof this.MediaStoreConfig.ChooseImageList) {
        case 'string':
          if (this.$store.state.user.permission.StaticDomain) {
            if (this.NowChooseLanguage === 'default') {
              this.$set(this.Data[type], this.ChooseImageIndex, {
                URL: this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList,
                Alt: ''
              })
            } else {
              this.$set(this.Data.LanguageData[this.NowChooseLanguage][type], this.ChooseImageIndex, {
                URL: this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList,
                Alt: ''
              })
            }
          } else {
            storageInstance.ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
              if (this.NowChooseLanguage === 'default') {
                this.$set(this.Data[type], this.ChooseImageIndex, {URL, Alt: ''})
              } else {
                this.$set(this.Data.LanguageData[this.NowChooseLanguage][type], this.ChooseImageIndex, {URL, Alt: ''})
              }
            })
          }
          break
        case 'object':
          if (Array.isArray(this.MediaStoreConfig.ChooseImageList)) {
            this.MediaStoreConfig.ChooseImageList.forEach(imgPath => {
              let PushData
              if (this.$store.state.user.permission.StaticDomain) {
                PushData = {
                  URL: this.$store.state.user.permission.StaticDomain + imgPath,
                  Alt: ''
                }
                if (this.NowChooseLanguage === 'default') {
                  this.Data[type].push(PushData)
                } else {
                  this.Data.LanguageData[this.NowChooseLanguage][type].push(PushData)
                }
              } else {
                storageInstance.ref().child(imgPath).getDownloadURL().then((URL) => {
                  PushData = {
                    URL,
                    Alt: ''
                  }
                  if (this.NowChooseLanguage === 'default') {
                    this.Data[type].push(PushData)
                  } else {
                    this.Data.LanguageData[this.NowChooseLanguage][type].push(PushData)
                  }
                })
              }
            })
          }
          break
      }
    },
    ResolveImageURL () {
      if (this.$store.state.user.permission.StaticDomain) {
        return Promise.resolve(this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
      }
      return this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
        return URL
      })
    },
    OpenMediaMeta(type = 'Carousel', index = false) {
      this.MediaMetaConfig.Display = !this.MediaMetaConfig.Display
      this.ChooseImageType = type
      switch (type) {
        case 'Carousel':
          if (index === false) {
            return false
          }
          if (this.NowChooseLanguage === 'default') {
            this.MediaMetaConfig.ImageMeta = this.Data[type][index]
          } else {
            this.MediaMetaConfig.ImageMeta = this.Data.LanguageData[this.NowChooseLanguage][type][index]
          }
          this.ChooseImageIndex = index
          break
        case 'Cover':
          if (this.NowChooseLanguage === 'default') {
            this.MediaMetaConfig.ImageMeta.URL = this.Data[type]
          } else {
            this.MediaMetaConfig.ImageMeta.URL = this.Data.LanguageData[this.NowChooseLanguage][type]
          }
          break
      }
    },
    SetMeta() {
      switch (this.ChooseImageType) {
        case 'Cover':
          this.Data.Cover = this.MediaMetaConfig.ImageMeta.URL
          break
      }
      this.MediaMetaConfig.ImageMeta = {
        URL: ''
      }
      this.ChooseImageType = ''
      this.ChooseImageIndex = false
      this.MediaMetaConfig.Display = false
    },
    RemoveImage(type = '', index) {
      if (this.NowChooseLanguage === 'default') {
        this.Data[type].splice(index, 1)
      } else {
        this.Data.LanguageData[this.NowChooseLanguage][type].splice(index, 1)
      }
    },
    AddVariableArrayInput(variable) {
      if (!this.Data.Variable[variable]) {
        this.$set(this.Data.Variable, variable, [])
      }
      this.Data.Variable[variable].push({
        Value: ''
      })
    },
    RemoveVariableArrayInput(variable, index) {
      this.Data.Variable[variable].splice(index, 1)
    },
    SearchAccount(Email) {
      this.OwnerSearchLoading = true
      this.TempAccount = Email
      setTimeout(() => {
        if (this.TempAccount === Email) {
          return this.$store.dispatch('InnerRequest', {
            url: '/user/list',
            method: 'post',
            data: {
              Email
            }
          }).then(({data}) => {
            this.OwnerSearchLoading = false
            this.SearchAccountData.push(...data.list.map(item => {
              return {
                Account: item.Account,
                Name: item.Name,
                Email: item.Email,
                Cover: item.Cover,
                Class: item.Class,
                Uid: item.Uid,
                Variable: item.Variable
              }
            }))
          }).catch((err) => {
            this.OwnerSearchLoading = false
            throw err
          })
        }
      }, 1000)
    },
    SynchronizeToWordPress() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/content/wp/sync',
        method: 'post',
        data: {
          Slug: this.Data.Slug
        }
      }).then(() => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Post/SyncWPSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Post/SyncWPFail') + ' :' + err.msg,
          type: 'error'
        })
        throw err
      })
    },
    CopyToClipboard (string) {
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Global/CopySuccess'),
          type: 'success'
        })
        return navigator.clipboard.writeText(string)
      }
      return Promise.reject('The Clipboard API is not available.');
    }
  }
}
</script>
